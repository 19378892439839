import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row, Button, Spinner } from "reactstrap"

import { CopyToClipboard } from "react-copy-to-clipboard"
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"
import ResendEmailModal from "./ResendEmailModal"
import RefundModal from "./RefundModal"
import RefundModalAPS from "./RefundModalAPS"

const Actions = props => {
  const {
    paymentrequestDetails,
    isEmailLoaded,
    refundDetails,
    user,
    paymentHistoryDetails,
    paymentRequestDetail,
  } = props
  const [modal, setModal] = useState(false)
  const [modalRefund, setModalRefund] = useState(false)

  const [modalRefundAPS, setModalRefundAPS] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [pgUsed, setPGUsed] = useState()
  const [copyLink, setCopyLink] = useState({
    value: "",
    copied: false,
  })
  //  const { handleSendPaymentLinkEmail } = useContext(PaymentActionsContext)
  //console.log(paymentHistoryDetails)
  useEffect(() => {
    paymentHistoryDetails?.map(ph => {
      if (
        ph.paymentStatus === "Success" ||
        ph.paymentStatus === "Refund Failed"
      )
        setPGUsed(ph)
    })
  }, [paymentHistoryDetails])
  useEffect(() => {}, [pgUsed])
  function handleRefundPayment() {
    // console.log(request)
    toggleModalRefund()
  }
  function handleRefundPaymentAPS() {
    // console.log(request)
    toggleModalRefundAPS()
  }
  const toggleModal = () => {
    setModal(!modal)
  }

  const toggleModalRefund = () => {
    setModalRefund(!modalRefund)
  }
  const toggleModalRefundAPS = () => {
    setModalRefundAPS(!modalRefundAPS)
  }
  // console.log(user)
  return (
    <>
      <ResendEmailModal
        isOpen={modal}
        toggle={toggleModal}
        paymentrequestDetails={paymentrequestDetails}
      />
      {paymentrequestDetails.paymentStatus === "Success" ||
      paymentrequestDetails.paymentStatus === "Refund Failed" ? (
        <RefundModal
          paymentrequestDetails={paymentrequestDetails}
          refundDetails={refundDetails}
          isOpen={modalRefund}
          toggle={toggleModalRefund}
          paymentRequestDetail={paymentRequestDetail}
        />
      ) : null}
      {/* {paymentrequestDetails.paymentStatus === "Success" ||
      paymentrequestDetails.paymentStatus === "Refund Failed" ? (
        <RefundModalAPS
          paymentrequestDetails={paymentrequestDetails}
          refundDetails={refundDetails}
          isOpen={modalRefundAPS}
          toggle={toggleModalRefundAPS}
          paymentRequestDetail={paymentRequestDetail}
        />
      ) : null} */}
      <Card>
        <CardBody>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8" className="item-center">
              <div className="button-items">
                {isEmailLoaded ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light"
                    style={{ width: "180px" }}
                  >
                    <i className="fas fa-paper-plane label-icon"></i>{" "}
                    <Spinner
                      className="mr-2"
                      color="info"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect btn-label waves-light"
                    onClick={toggleModal}
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    <i className="fas fa-paper-plane label-icon"></i> Send
                    Payment Link
                  </button>
                )}

                {/* copy link section */}

                <CopyToClipboard
                  text={paymentrequestDetails.generatedLinkShort}
                  onCopy={() => setCopyLink({ copied: true })}
                >
                  <Button
                    color="primary"
                    className="btn btn-success waves-effect btn-label waves-light"
                    onClick={() => {
                      setsuccess_msg(true)
                    }}
                    id="sa-success"
                  >
                    <i className="far fa-copy label-icon"></i> Copy Payment Link
                  </Button>
                </CopyToClipboard>

                {pgUsed?.paymentGatewayId === "PG00004" &&
                (paymentrequestDetails?.paymentStatus === "Success" ||
                  paymentrequestDetails?.paymentStatus === "Refund Failed") &&
                user?.role !== "User" ? (
                  <button
                    type="button"
                    className="btn btn-danger waves-effect btn-label waves-light"
                    onClick={() => {
                      handleRefundPayment()
                    }}
                  >
                    <i className="fas fa-dollar-sign label-icon "></i> Refund
                    Payment
                  </button>
                ) : null}
                {/* {pgUsed?.paymentGatewayId === "PG00002" &&
                (paymentrequestDetails?.paymentStatus === "Success" ||
                  paymentrequestDetails?.paymentStatus === "Refund Failed") &&
                user?.role !== "User" ? (
                  <button
                    type="button"
                    className="btn btn-danger waves-effect btn-label waves-light"
                    onClick={() => {
                      toggleModalRefundAPS()
                    }}
                  >
                    <i className="fas fa-dollar-sign label-icon "></i> Refund
                    Payment
                  </button>
                ) : null} */}
              </div>
            </Col>
            <Col lg="2"></Col>
          </Row>
          {success_msg ? (
            <SweetAlert
              title="Link Copied! Paste Now"
              success
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            >
              {paymentrequestDetails.generatedLinkShort}
            </SweetAlert>
          ) : null}
        </CardBody>
      </Card>
    </>
  )
}

export default Actions
