import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"

export const API_URL = "https://pal.acoqa.com/api/"
//const API_URL = "http://localhost:62592/api/"

const axiosApi = axios.create({
  baseURL: API_URL,
})
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)
async function getAuthorized() {
  const token = authHeader()
  axiosApi.defaults.headers.common["Authorization"] = token.Authorization
}

export async function get(url) {
  const uri = API_URL + url
  await getAuthorized()
  return await axiosApi
    .get(uri)
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }
    })
}

export async function post(url, data) {
  const uri = API_URL + url
  await getAuthorized()
  return await axiosApi
    .post(uri, data)
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

      // if (
      //   error.message === "Request failed with status code 401" &&
      //   axiosApi.defaults.headers.common["Authorization"] !== undefined
      // ) {
      //   //window.location.reload();
      //   window.location = "/sessiontimeout"
      // }
      // if (error.request) {
      //   return JSON.parse(error.request.response)
      // } else if (error.response) {
      //   // console.log("Error Response", error.response)
      // } else {
      //   // console.log(error)
      // }
    })
}
export async function getById(url, data) {
  const uri = API_URL + url + "/item?searchValue=" + data
  await getAuthorized()
  return await axiosApi
    .get(uri)
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

      // if (
      //   error.message === "Request failed with status code 401" &&
      //   axiosApi.defaults.headers.common["Authorization"] !== undefined
      // ) {
      //   //window.location.reload();
      //   window.location = "/sessiontimeout"
      // }
    })
}
export async function put(url, data, config = {}) {
  await getAuthorized()
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

      // if (
      //   error.message === "Request failed with status code 401" &&
      //   axiosApi.defaults.headers.common["Authorization"] !== undefined
      // ) {
      //   //window.location.reload();
      //   window.location = "/sessiontimeout"
      // }
    })
}

export async function del(url, config = {}) {
  await getAuthorized()
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

      // if (
      //   error.message === "Request failed with status code 401" &&
      //   axiosApi.defaults.headers.common["Authorization"] !== undefined
      // ) {
      //   //window.location.reload();
      //   window.location = "/sessiontimeout"
      // }
    })
}

export async function patch(url, data, config = {}) {
  await getAuthorized()
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      // if (error.response.status === 401) {
      //   window.location = "/sessiontimeout"
      // }

      if (error.request) {
        return JSON.parse(error.request.response)
      } else if (error.response) {
        console.log("Error Response", error.response)
        return error.response.data
      } else {
        console.log(error)
      }

      // if (
      //   error.message === "Request failed with status code 401" &&
      //   axiosApi.defaults.headers.common["Authorization"] !== undefined
      // ) {
      //   //window.location.reload();
      //   window.location = "/sessiontimeout"
      // }
    })
}

// export async function paymentrequests(url) {
//   const uri = API_URL + url
//   await getAuthorized()
//   return await axiosApi.get(uri).catch(error => {
//     if (
//       error.message === "Request failed with status code 401" &&
//       axiosApi.defaults.headers.common["Authorization"] !== undefined
//     ) {
//       //window.location.reload();
//       window.location = "/sessiontimeout"
//     }
//   })
// }
