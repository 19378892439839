import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { isEmpty } from "lodash"
import { Col, Container, Row } from "reactstrap"
import { ToastContainer } from "react-toastify"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Breadcrumbs from "components/Common/Breadcrumb"
import SweetAlert from "react-bootstrap-sweetalert"
import PaymentRequestDetails from "./PaymentRequestDetails"
import PaymentHistory from "./PaymentHistory"
import Activities from "./PaymentRequestActivities"
import PaymentRequestItems from "./PaymentRequestItems"
import Actions from "./Actions"
import { getPaymentRequestDetail, sendPaymentRequestEmail } from "store/actions"
import LoadingSpinnerThreeDots from "../../helpers/commonhelpers/loadingSpinner"
import errorCatching from "../../helpers/commonhelpers/errorCatching"
export const PaymentActionsContext = React.createContext()
import AuthUser from "components/AuthUser/AuthUser"

toastr.options = {
  positionClass: "toast-top-right",
  closeButton: false,
  debug: false,
  progressBar: true,
  preventDuplicates: false,
  newestOnTop: true,
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
  showDuration: 300,
  hideDuration: 300,
}

const PaymentRequestOverview = props => {
  const {
    paymentRequestDetail,
    loading,
    loadingEmail,
    match: { params },
    onGetPaymentRequestDetail,
    error,
    dibsyRefundDetails,
    apsRefundDetails,
  } = props

  const [isLoaded, setIsLoaded] = useState(loading)
  const [isLoadedEmail, setIsLoadedEmail] = useState(loading)
  const [isEmailSuccess, setIsEmailSuccess] = useState(null)
  const [errorResponse, setErrorResponse] = useState(undefined)
  const [user, setUser] = useState(AuthUser())
  const [refundDetails, setRefundDetails] = useState()
  const [paymentHistoryDetails, setPaymentHistoryDetails] = useState()
  const [refund, setRefund] = useState()
  const [success_dlg, setsuccess_dlg] = useState(false)
  const [prStatus, setPRStatus] = useState()

  const dispatch = useDispatch()

  useEffect(() => {
    if (params && params.id) {
      onGetPaymentRequestDetail(params.id)
    } else {
      // onGetPaymentRequestDetail(0) //remove this after full integration
    }
  }, [params, onGetPaymentRequestDetail])

  useEffect(() => {
    //console.log(paymentRequestDetail)
    if (paymentRequestDetail) {
      paymentRequestDetail?.paymentHistoryDetails?.map(ph => {
        if (ph.isSuccessful) {
          setRefundDetails(ph)
        }
      })
      setPaymentHistoryDetails(paymentRequestDetail?.paymentHistoryDetails)
      setPRStatus(paymentRequestDetail?.paymentRequestDetails?.paymentStatus)
    }
  }, [paymentRequestDetail])
  useEffect(() => {
    //console.log(paymentHistoryDetails?.[0])
    if (paymentHistoryDetails?.[0]?.operation === "REFUND") {
      setRefund(paymentHistoryDetails?.[0])
    }
  }, [paymentHistoryDetails])
  useEffect(() => {
    // console.log(refund)
  }, [refund])
  useEffect(() => {}, [prStatus])

  useEffect(() => {
    //console.log(dibsyRefundDetails)
    if (dibsyRefundDetails) {
      setsuccess_dlg(true)
    }
  }, [dibsyRefundDetails])
  useEffect(() => {
    //console.log(dibsyRefundDetails)
    if (apsRefundDetails) {
      setsuccess_dlg(true)
    }
  }, [apsRefundDetails])
  useEffect(() => {
    //console.log(refundDetails)
  }, [refundDetails])
  useEffect(() => {
    setIsLoaded(loading)
  }, [loading])

  useEffect(() => {
    if (loadingEmail && loadingEmail === true) {
      toastr.options = {
        timeOut: 5000,
      }
      toastr.success("", "Email Success")
    }

    if (loadingEmail === undefined) {
      toastr.options = {
        timeOut: 1000,
      }
      toastr.info("", "Email Sending")
    }

    if (loadingEmail === false) {
      toastr.options = {
        timeOut: 5000,
      }
      toastr.error("", "Opps Email Not Sent")
    }
  }, [loadingEmail])

  //ERROR CATCHING
  useEffect(() => {
    if (error !== undefined && !isEmpty(error)) setErrorResponse(error)
  }, [error])

  useEffect(() => {}, [errorResponse])
  function sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }
  function handleSendPaymentLinkEmail(recipient) {
    var data = {
      id: paymentRequestDetail?.paymentRequestDetails?._id,
      recipient:
        paymentRequestDetail?.paymentRequestDetails?.customerDetails.email,
      actiontype: "Resending Payment Request",
    }

    if (!isEmpty(recipient) && recipient !== "") {
      data.recipient = recipient
      if (
        paymentRequestDetail?.paymentRequestDetails?.customerDetails.email !==
        recipient
      ) {
        data.actiontype = "Resending Payment Request (New Email)"
      }
    }

    if (data.id) {
      dispatch(sendPaymentRequestEmail(data))
    }
  }

  function setEmailStatus(status) {
    setIsEmailSuccess(status)
  }

  function alertEmailMessage() {
    if (isEmailSuccess) {
      return (
        <SweetAlert
          title="Email Resent Successfully"
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setIsLoadedEmail(false)
          }}
          onCancel={() => {
            setIsLoadedEmail(false)
          }}
        >
          {" "}
        </SweetAlert>
      )
    } else {
      return (
        <SweetAlert
          title="Something went wrong. Email Not Sent"
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setIsLoadedEmail(false)
          }}
          onCancel={() => {
            setIsLoadedEmail(false)
          }}
        >
          {" "}
        </SweetAlert>
      )
    }
  }

  const PaymentActionsContextValue = {
    handleSendPaymentLinkEmail,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Payment Request List "
            breadcrumbItem=" Overview"
          />
          {success_dlg ? (
            <SweetAlert
              timeout={1000}
              success
              showConfirm={false}
              title={"Refund Request Sent"}
              onConfirm={() => {
                setsuccess_dlg(false)
                onGetPaymentRequestDetail(params.id)
                //window.location.reload()
              }}
            >
              {"Refund in progress."}
            </SweetAlert>
          ) : null}
          {isLoaded ? (
            <LoadingSpinnerThreeDots />
          ) : (
            <>
              {errorCatching(errorResponse, setErrorResponse)}

              {!isEmpty(props.paymentRequestDetail) && (
                <>
                  <Row>
                    <Col lg="12">
                      <PaymentActionsContext.Provider
                        value={PaymentActionsContextValue}
                      >
                        <Actions
                          paymentrequestDetails={
                            paymentRequestDetail?.paymentRequestDetails
                          }
                          user={user}
                          refundDetails={refundDetails}
                          isEmailLoaded={isLoadedEmail}
                          paymentHistoryDetails={
                            paymentRequestDetail?.paymentHistoryDetails
                          }
                          paymentRequestDetail={paymentRequestDetail}
                        />
                      </PaymentActionsContext.Provider>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8">
                      <PaymentRequestDetails
                        paymentrequestDetails={
                          paymentRequestDetail?.paymentRequestDetails
                        }
                        userDetails={paymentRequestDetail?.userDetails}
                        refund={refund}
                      />
                    </Col>

                    <Col lg="4">
                      <Activities
                        userLogs={paymentRequestDetail?.userLogs}
                        prStatus={prStatus}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="8">
                      <PaymentHistory
                        paymentHistoryDetails={
                          paymentRequestDetail?.paymentHistoryDetails
                        }
                        user={user}
                      />
                    </Col>

                    <Col lg="4">
                      <PaymentRequestItems
                        source={
                          paymentRequestDetail?.paymentRequestDetails
                            ?.itemSource
                        }
                        purchasedItems={paymentRequestDetail?.purchasedItems}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

PaymentRequestOverview.propTypes = {
  paymentRequestDetail: PropTypes.object,
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool,
  match: PropTypes.object,
  onGetPaymentRequestDetail: PropTypes.func,
}

const mapStateToProps = pr => ({
  paymentRequestDetail: pr.paymentRequests.paymentRequestDetail,
  loading: pr.paymentRequests.loading,
  loadingEmail: pr.paymentRequests.loadingEmail,
  error: pr.paymentRequests.error,
  dibsyRefundDetails: pr.paymentRequests.dibsyRefundDetails,
  apsRefundDetails: pr.paymentRequests.apsRefundDetails,
})

const mapDispatchToProps = dispatch => ({
  onGetPaymentRequestDetail: id => dispatch(getPaymentRequestDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentRequestOverview))
