import React, { useState, useEffect } from "react"
import { Col, Row, Label } from "reactstrap"
import { PaymentListSelectionMerchant } from "../../components/PaymentTypesSelect/PaymentList"
import {
  payfortLogo,
  dibsyLogo,
  applepayLogo,
  googlepayLogo,
} from "components/SharedImages"
import { isMobile } from "react-device-detect"
function EnabledPaymentOptionv2(props) {
  const { options, handlePaymentOptionChanges } = props
  // const payfortLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430872/merchant/Amazon-Payment-Services_Logo_copy_ixkqm0.jpg"
  // const dibsyLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736430504/merchant/dibsy_xchibk.svg"

  // const applepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422361/merchant/applepay_oope1a.png"
  // const googlepayLogo =
  //   "https://res.cloudinary.com/developerqa/image/upload/v1736422390/merchant/googlepay_vciqln.png"

  const [ptList, setPtList] = useState()
  useEffect(() => {
    //console.log(options)
    if (options) {
      setPtList(options)
    }
  }, [options])
  useEffect(() => {
    // console.log(ptList)
  }, [ptList])

  function handlePGLogo(value) {
    switch (value) {
      case "2":
        return payfortLogo
      case "4":
        return dibsyLogo
    }
  }
  function handlePGLogoSize(value) {
    if (isMobile) {
      switch (value) {
        case "2":
          return "90%"
        case "4":
          return "30%"
      }
    } else {
      switch (value) {
        case "2":
          return "20%"
        case "4":
          return "6%"
      }
    }
  }
  function handlePaymentOption(value) {
    switch (value) {
      case "credit":
        return ""
      case "debit":
        return ""
      case "applepay":
        return applepayLogo
      case "googlepay":
        return googlepayLogo
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          {ptList?.map((pt, index) => {
            return (
              <div key={index}>
                <Row>
                  <Col>
                    {/* {" "}
                    <h5 className="color-primary">
                      <b>{pt.pgName}</b>
                    </h5> */}
                    <img
                      src={handlePGLogo(pt.id)}
                      width={handlePGLogoSize(pt.id)}
                      alt="pg logo"
                      className=" pointer"
                      onClick={() => {
                        handleOpenClickChange(card.id, index, {
                          checked: !open[index]?.checked,
                        })
                      }}
                    />
                  </Col>
                </Row>
                {/* <Row className="my-2 color-primary">
                      <Col className="ml-4">
                        <Label>
                          <b>Payment Type:</b>
                        </Label>
                      </Col>
                      <Col className="">
                        <b>Status:</b>
                      </Col>
                    </Row> */}
                {pt?.cards?.map((p, i) => {
                  return (
                    <>
                      {p?.basis ? (
                        <Row key={i} className="my-2">
                          <Col className="ml-4">
                            <input
                              name={p.id}
                              id={p.id}
                              className="pointer mx-1"
                              type="radio"
                              checked={p?.enabled}
                              onClick={() => {
                                handlePaymentOptionChanges(
                                  parseInt(p?.id),
                                  p?.enabled
                                )
                              }}
                            />
                            <Label>{p?.basis.toUpperCase()}</Label>
                            {/* {handlePaymentOption(p?.basis) !== "" ? (
                              <img
                                src={handlePaymentOption(p?.basis)}
                                width={"50px"}
                                height={"20px"}
                                alt="pg logo"
                                className=" pointer "
                               
                              />
                            ) : (
                              <Label
                                check
                                className="pointer "
                              
                              >
                                {p?.basis.toUpperCase()}
                              </Label>
                            )} */}
                          </Col>
                          {/* <Col className="">
                      {statusPillsBool(p.enabled, p.enabled.toString(), true)}
                    </Col> */}
                        </Row>
                      ) : null}
                    </>
                  )
                })}
              </div>
            )
          })}
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default EnabledPaymentOptionv2
